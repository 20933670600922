import { Button, Card, Space } from 'antd';
import { NormalProgrammeComponent, EgGrid, ExportModal } from 'egenie-utils';
import { Provider, inject, observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';
import { Store } from './store';

@inject('store')
@observer
class Content extends React.Component<{ store?: Store; }> {
  render() {
    const {
      searchTypeList,
      handleQueryStatus,
    } = this.props.store;
    return (
      <div className={styles.searchPanel}>
        {searchTypeList.map((item) => (
          <div
            className={styles.item}
            key={item.key}
          >
            <span className={styles.itemName}>
              {item.name}
            </span>
            <span
              className={styles.itemVlaue}
              onClick={handleQueryStatus.bind(this, item.code)}
            >
              {item.value}
            </span>
          </div>
        ))}
      </div>
    );
  }
}

@inject('store')
@observer
class BtnContainer extends React.Component<{ store?: Store; }> {
  render() {
    const { handleExport } = this.props.store;
    return (
      <Space
        size="small"
        style={{ marginBottom: 8 }}
      >
        <Button onClick={() => handleExport()}>
          导出
        </Button>
      </Space>
    );
  }
}

const store = new Store();
export default function() {
  const {
    normalProgramme,
    parentGridModel,
    subStore: { childGridModel },
    exportStore,
  } = store;
  return (
    <>
      <Provider store={store}>
        <div className={styles.onlyCode}>
          <Card size="small">
            <NormalProgrammeComponent store={normalProgramme}/>
          </Card>
          <Content/>
          <BtnContainer/>
          <div className={styles.tableBox}>
            <div className={styles.tableItem}>
              <EgGrid store={parentGridModel}/>
            </div>
            <div className={styles.tableItem}>
              <EgGrid store={childGridModel}/>
            </div>
          </div>
        </div>
      </Provider>
      <ExportModal store={exportStore}/>
    </>
  );
}
