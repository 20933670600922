import { Modal } from 'antd';
import type { BaseData, FilterConfigData, PaginationData } from 'egenie-utils';
import { formatFilterConfigData, EgGridModel, ImgFormatter, NormalProgramme, request, ExportStore } from 'egenie-utils';
import { action, observable } from 'mobx';
import { nanoid } from 'nanoid';
import qs from 'qs';
import React from 'react';
import { getWarehouseBin } from '../../utils';
import type { MainItem, SubItem } from './interface';

function handleOpenTab(row: SubItem): void {
  const { businessNo } = row;
  const params = {
    wmsOtherStockOrderNo: `${businessNo}`, // 入库单 & 出库单
    wmsReceiveSourceType: `wmsReceiveOrderNo,${businessNo}`, // 收货单
    serialNo: `${businessNo}`, // 调拨单
    stockTakeOrderNo: `${businessNo}`, // 盘点单
    shiftBinNo: `${businessNo}`, // 移库单
    platformOrderCode: `${businessNo}`, // 发货单
    cloudPmsPurchaseOrderNo: `${businessNo}`, // 采购单
    wmsAfterSaleOrderNo: `${businessNo}`, // 售后单
  };

  if (row.sourceId) {
    try {
      top.egenie.openTabId(row.sourceId, qs.stringify(params));
    } catch (e) {
      console.log(e);
    }
  }
}

class SubStore {
  constructor(parent: Store) {
    this.parent = parent;
  }

  public parent: Store;

  // 查询&获取子表（日志）
  @action public queryChildTable = (data = {}): Promise<unknown> => {
    return request<PaginationData<SubItem>>({
      method: 'post',
      url: '/api/cloud/wms/rest/unique/code/record/query',
      data: {
        uniqueCode: this.parent.uniqueCode,
        ...data,
      },
    })
      .then((res) => {
        const list = (res?.data?.list || []).map((item) => ({
          id: nanoid(),
          ...item,
        }));
        this.childGridModel.rows = list || [];
        this.childGridModel.total = res?.data?.totalCount || 0;
      });
  };

  public childGridModel = new EgGridModel({
    columns: [
      {
        key: 'status',
        name: '状态',
        width: 60,
      },
      {
        key: 'operationName',
        name: '操作记录',
        width: 120,
      },
      {
        key: 'ownerName',
        name: '货主',
        width: 150,
        resizable: true,
      },

      {
        key: 'createTimeString',
        name: '创建日期',
        width: 150,
      },
      {
        key: 'creatorName',
        name: '操作员',
        width: 120,
      },
      {
        key: 'businessNo',
        name: '单据编号',
        width: 200,
        formatter: ({ row }): JSX.Element => (
          <span
            onClick={() => handleOpenTab(row)}
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            {row.businessNo}
          </span>
        ),
      },
      {
        key: 'warehouseName',
        name: '仓库',
        width: 150,
      },
      {
        key: 'warehouseBinNo',
        name: '库位',
        width: 150,
      },
      {
        key: 'warehouseAreaName',
        name: '库区',
        width: 150,
      },

    ].map((info) => ({
      resizable: true,
      sortable: false,
      ...info,
    })),
    rows: [],
    rowHeight: 38,
    primaryKeyField: 'id',
    sortByLocal: false,
    pageSize: 20,
    showCheckBox: false,
    showReset: false,
    showPager: true,
    showRefresh: false,
    showSelectedTotal: false,
    setColumnsDisplay: true,
    gridIdForColumnConfig: '/egenie-cloud-wms/onlyCode/index/sub',
    api: {
      onQuery: this.queryChildTable,
      onPageChange: (page: number, pageSize: number) => this.queryChildTable({
        page,
        pageSize,
      }),
      onShowSizeChange: (page: number, pageSize: number) => this.queryChildTable({
        page,
        pageSize,
      }),
    },
  });
}

export class Store {
  constructor() {
    this.subStore = new SubStore(this);
    getWarehouseBin()
      .then((data) => this.normalProgramme.filterItems.addDict({ warehouseBinId: data }));

    request<FilterConfigData>({
      url: '/api/cloud/baseinfo/rest/filterSet2/getConfig2',
      params: {
        dictList: 'unique_code_status',
        module: '',
      },
    })
      .then((info) => {
        const list = formatFilterConfigData(info, { unique_code_status: 'uniqueCodeStatues' });
        this.normalProgramme.filterItems.addDict(list.reduce((prev, current) => ({
          ...prev,
          [current.field]: current.data,
        }), {}));
      });
  }

  public subStore: SubStore;

  public exportStore: ExportStore = new ExportStore({ parent: null });

  public handleExport = () => {
    const fileName = '唯一码';
    const exportType = 'cloud_unique_code';
    const queryParam = this.params;
    const queryParamShow = this.normalProgramme.filterItems.translateParams.join(' ');

    Modal.confirm({
      title: '提示',
      content: '未选择数据将导出全部数据?',
      onOk: () => {
        this.exportStore.onShow(fileName, exportType, '', queryParam, queryParamShow);
      },
    });
  };

  @observable public searchTypeList = [
    {
      key: 'bron',
      value: 0,
      name: '生成',
      code: 0,
    },
    {
      key: 'arrival',
      value: 0,
      name: '到货',
      code: 1,
    },
    {
      key: 'stockIn',
      value: 0,
      name: '入库',
      code: 8,
    },
    {
      key: 'stockOut',
      value: 0,
      name: '出库',
      code: 7,
    },
    {
      key: 'pick',
      value: 0,
      name: '拣货',
      code: 3,
    },
    {
      key: 'print',
      value: 0,
      name: '打印',
      code: 6,
    },
    {
      key: 'weight',
      value: 0,
      name: '称重',
      code: 5,
    },
    {
      key: 'discard',
      value: 0,
      name: '作废',
      code: 13,
    },
    {
      key: 'examine',
      value: 0,
      name: '验货',
      code: 4,
    },
    {
      key: 'certificate',
      value: 0,
      name: '合格证',
      code: 14,
    },
    {
      key: 'changeMark',
      value: 0,
      name: '换标',
      code: 15,
    },
  ];

  @observable public uniqueCodeStatus: number;

  @observable public uniqueCode: string;

  private get params() {
    const params = this.normalProgramme.filterItems.params;
    return {
      ...params,
      uniqueCodeStatus: this.uniqueCodeStatus,
    };
  }

  @action public handleQuery = (data = {}): Promise<unknown> => {
    if (!this.uniqueCodeStatus) {
      request<BaseData<{[keyName: string]: number; }>>({
        method: 'post',
        url: '/api/cloud/wms/rest/unique/code/count',
        data: this.params,
      })
        .then((res) => {
          this.searchTypeList = this.searchTypeList.map((item) => ({
            ...item,
            value: (res.data || {})[item.key] || 0,
          }));
        });
    }

    return request<PaginationData<MainItem>>({
      url: '/api/cloud/wms/rest/unique/code/query',
      method: 'POST',
      data: {
        ...data,
        ...this.params,
      },
    })
      .then((res) => {
        this.parentGridModel.rows = res.data.list;
        this.parentGridModel.total = res.data.totalCount;
        if (res.data.totalCount === 0) {
          this.subStore.childGridModel.rows = [];
        }
      });
  };

  public parentGridModel = new EgGridModel({
    columns: [
      {
        key: 'skuNo',
        name: 'sku编码',
        width: 200,
      },
      {
        key: 'pic',
        name: '图片',
        width: 60,
        formatter: ({ row }) => {
          return <ImgFormatter value={row.pic}/>;
        },
      },
      {
        key: 'status',
        name: '当前状态',
        width: 80,
        resizable: true,
      },
      {
        key: 'qualityStatus',
        name: '质检标记',
        width: 80,
      },
      {
        key: 'ownerName',
        name: '当前货主',
        width: 80,
      },
      {
        name: '供应商',
        key: 'vendorNo',
        width: 150,
      },
      {
        key: 'color',
        name: '颜色',
        width: 100,
        sortable: true,
      },
      {
        key: 'size',
        name: '尺码',
        width: 100,
        sortable: true,
      },
      {
        key: 'uniqueCode',
        name: '唯一码',
        width: 260,
      },
      {
        key: 'warehouseAreaNo',
        name: '库区',
        width: 150,
      },
      {
        key: 'warehouseBinNo',
        name: '库位',
        width: 150,
      },
    ].map((info) => ({
      resizable: true,
      sortable: false,
      ...info,
    })),
    rows: [],
    rowHeight: 38,
    primaryKeyField: 'uniqueCode',
    sortByLocal: false,
    pageSize: 20,
    showCheckBox: false,
    showReset: false,
    showPager: true,
    showRefresh: false,
    showSelectedTotal: false,
    setColumnsDisplay: true,
    gridIdForColumnConfig: '/egenie-cloud-wms/onlyCode/index/main',

    forceRowClick: true, // 点击行更新字表 数据
    api: {
      onQuery: this.handleQuery,
      onPageChange: (page: number, pageSize: number) => this.handleQuery({
        page,
        pageSize,
      }),

      // onSort: this.onSort,
      onShowSizeChange: (page: number, pageSize: number) => this.handleQuery({
        page,
        pageSize,
      }),
      onRowClick: (rowId: string) => {
        this.subStore.childGridModel.current = 1;
        this.uniqueCode = rowId;
        this.subStore.childGridModel.onQuery();
      },
    },
  });

  @observable public normalProgramme = new NormalProgramme({
    count: 6,
    filterItems: [
      {
        type: 'select',
        field: 'dateType',
        label: '日期类型',
        value: '1',
        data: [
          {
            value: '1',
            label: '创建日期',
          },
          {
            value: '2',
            label: '快递单打印时间',
          },
          {
            value: '3',
            label: '到货时间',
          },
          {
            value: '4',
            label: '退货时间',
          },
        ],
      },
      {
        type: 'dateRange',
        field: 'date',
        label: '日期',
        format: 'YYYY-MM-DD',
        startParamsField: 'startTime',
        endParamsField: 'endTime',
      },
      {
        type: 'input',
        field: 'uniqueCode',
        label: '唯一码',
      },
      {
        type: 'input',
        field: 'barCode',
        label: '条形码',
      },
      {
        type: 'input',
        field: 'skuNo',
        label: 'sku编码',
      },
      {
        type: 'input',
        field: 'orderCode',
        label: '单据编号',
      },
      {
        type: 'select',
        field: 'warehouseBinId',
        label: '库位',
      },
      {
        type: 'select',
        field: 'uniqueCodeStatues',
        label: '唯一码状态',
        mode: 'multiple',
      },
      {
        type: 'select',
        field: 'qualityStatus',
        label: '质检标记',
        mode: 'multiple',
        data: [
          {
            value: '0',
            label: '未质检',
          },
          {
            value: '1',
            label: '质检次品',
          },
          {
            value: '2',
            label: '质检正品',
          },
          {
            value: '3',
            label: '无需质检',
          },
        ],
      },
    ],

    handleSearch: () => {
      this.uniqueCodeStatus = null;
      this.parentGridModel.current = 1;
      return this.parentGridModel.onQuery();
    },
  });

  @action public handleQueryStatus = (status: number): void => {
    this.parentGridModel.current = 1;
    this.uniqueCodeStatus = status;
    this.parentGridModel.onQuery();
  };
}
